import React from 'react';

const MedalIcon = ({
  width = null,
  height = null,
  color = '#EFBC1D',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.7 7.5C24.1 4 16.1 5.4 10.8 10.6a17 17 0 00-1.4 22.5 1 1 0 001.3.2c.4-.3.5-1 .2-1.4A15 15 0 0112 12 15.4 15.4 0 0130 9.2c.4.3 1 0 1.2-.4.3-.4 0-1-.4-1.2zM38.3 15a1 1 0 00-1.3-.4 1 1 0 00-.4 1.2 15 15 0 01-2.8 17.6c-5.5 5.4-14.2 5.9-20.2 1.2a1 1 0 00-1.4.2 1 1 0 00.2 1.3 17.2 17.2 0 0026-21.1zM35.8 11.4a17.3 17.3 0 00-1.5-1.5 1 1 0 00-1.3 0 1 1 0 000 1.4 15.6 15.6 0 011.4 1.3 1 1 0 001.4.1c.4-.3.4-1 0-1.3z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4 21c.7 1 .7 2.3 0 3.4l-1.2 2c-.2.3-.3.6-.2.9l.4 2.3c.1 1.2-.3 2.4-1.3 3.1l-2 1.4-.5.8-.6 2.3c-.2 1.2-1.2 2-2.3 2.4l-1 .2 6.6 14.6c.2.3.1.7 0 1a1 1 0 01-1 .3l-5.3-.6-3 4.3a1 1 0 01-1.7-.1l-6.7-15-1 .6a3.3 3.3 0 01-3.3 0l-.8-.5-6.8 14.9a1 1 0 01-1.6.1l-3-4.3-5.4.6a1 1 0 01-.9-.4 1 1 0 010-1l6.5-14.5-1-.2A3.2 3.2 0 016 37.2l-.6-2.3c0-.3-.3-.6-.5-.8l-2-1.4c-1-.7-1.5-1.9-1.3-3l.4-2.4c0-.3 0-.6-.2-1l-1.2-2c-.7-1-.7-2.3 0-3.3l1.2-2c.2-.3.3-.6.2-1l-.4-2.3c-.1-1.2.3-2.4 1.3-3l2-1.5.5-.7.6-2.3C6.2 7 7.2 6 8.3 5.8l2.4-.6c.3 0 .6-.2.7-.5L13 2.8c.7-1 1.9-1.5 3-1.3l2.5.4c.3 0 .6 0 .9-.2l2-1.2c1-.7 2.3-.7 3.4 0l2 1.2c.3.2.6.2 1 .2l2.3-.4c1.2-.2 2.4.3 3.1 1.3l1.5 2c.1.2.4.4.7.4l2.4.6c1.1.3 2 1.2 2.3 2.4l.6 2.3c0 .3.3.5.5.7l2 1.4c1 .7 1.4 2 1.3 3.1l-.4 2.4c0 .3 0 .6.2.9l1.2 2zM10.2 53.5l2.5 3.5 6-13.5h-.3l-2.4.3h-1l-3.1 7A1 1 0 0110 50l3.2-7-.4-.4-1.5-2-.2-.3-6 13.3 4.2-.5c.3 0 .6.1.8.4zm30.7.1l-4.2-.5a1 1 0 00-1 .4L33.5 57l-6.1-13.5h.3l2.4.3h1l3.2 7a1 1 0 001.7-.8l-3.2-7 .4-.4 1.5-2 .3-.3 6 13.3zm1.6-28.2l1.3-2c.3-.5.3-1 0-1.4l-1.3-2c-.4-.7-.5-1.5-.4-2.2l.4-2.4c0-.5-.1-1-.6-1.2L40 12.8c-.6-.5-1-1.1-1.2-1.9l-.6-2.3c-.1-.5-.5-.9-1-1L35 7.1c-.8-.2-1.4-.6-1.9-1.3L31.6 4c-.3-.4-.8-.6-1.3-.5l-2.4.4c-.7 0-1.5 0-2.1-.5l-2.1-1.2c-.4-.3-1-.3-1.4 0l-2 1.2c-.7.4-1.5.6-2.2.5l-2.4-.4c-.5-.1-1 .1-1.3.5L13 6c-.5.5-1.1 1-1.9 1.2l-2.3.5c-.5.1-.9.5-1 1l-.6 2.3c-.1.8-.6 1.4-1.2 1.9l-2 1.4c-.4.3-.6.7-.5 1.2l.4 2.4c.1.7 0 1.5-.4 2.2l-1.3 2c-.3.4-.3 1 0 1.4l1.3 2c.4.7.5 1.4.4 2.2l-.4 2.3c0 .5.1 1 .6 1.3L6 32.6c.6.4 1 1.1 1.2 1.8l.6 2.4c.1.4.5.8 1 1l2.3.5c.8.2 1.4.6 1.9 1.2l1.4 2c.3.4.8.6 1.3.5l2.4-.4a3.3 3.3 0 012.1.4l2.1 1.3c.4.2 1 .2 1.4 0l2-1.3c.7-.4 1.5-.5 2.2-.4l2.4.4c.5 0 1-.1 1.3-.6l1.4-1.9c.5-.6 1.1-1 1.9-1.2l2.3-.6c.5 0 .9-.5 1-1l.6-2.3c.1-.7.6-1.4 1.2-1.8l2-1.4c.4-.3.6-.8.5-1.3l-.4-2.3c-.1-.8 0-1.5.4-2.2z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7 18.6a1.7 1.7 0 011 3l-4.3 3.8 1.2 5.6c0 .7-.2 1.3-.7 1.7-.6.4-1.3.5-1.9.1L23 30l-5 2.8a1.7 1.7 0 01-1.9 0c-.5-.5-.8-1.1-.7-1.8l1.2-5.6-4.3-3.9c-.5-.4-.6-1.1-.4-1.8.2-.6.7-1 1.4-1.1l5.7-.7 2.4-5.2c.3-.6 1-1 1.6-1 .7 0 1.3.4 1.6 1L27 18l5.7.7zm-5.2 6.7c-.1-.4 0-.7.3-1l4.3-3.9-5.9-.7a1 1 0 01-.7-.5L23 13.9l-2.5 5.3a1 1 0 01-.7.5l-5.9.7 4.3 4c.3.2.4.5.3.9L17.4 31l5.1-2.9a1 1 0 011 0l5.1 2.9-1.1-5.7z"
        fill={color}
      />
    </svg>
  );
};

export default MedalIcon;
