import { graphql, Link } from 'gatsby';
import { Container } from '../components/Container';
import { Spacer } from '../components/Spacer';
import { Headline } from '../components/Headline';
import { Highlight } from '../components/Highlight';
import { Layout } from '../layout';
import { ServiceList } from '../modules/ServiceList';
import { desktopOnly, hideDesktop, phabletOnly } from '../theme/utils.css';
import {
  aboutUsBoxCss,
  aboutUsBoxTopCss,
  aboutUsLeftSideCss,
  aboutUsReadMoreCss,
  aboutUsRightText,
  aboutUsRowCss,
  aboutUsTitleCss,
  mastheadCss,
} from './index.css';
import { HeaderPrimary } from '../components/Header';
import { IconList } from '../modules/IconList';

import {
  ArchitectureIcon,
  CraneIcon,
  HouseIcon,
  MedalIcon,
} from '../components/Icons';
import { Text } from '../components/Text';
import { bricksCss } from '../components/Bricks.css';
import clsx from 'clsx';
import { Seo } from '../components/Seo';

export default function Homepage({ data, location }) {
  return (
    <Layout location={location}>
      <div className={mastheadCss}>
        <Container as="section">
          <Spacer y={14} />
          <Spacer y={8} className={phabletOnly} />
          <Headline align="center">
            Jouw <Highlight>specialist</Highlight> voor jouw{' '}
            <Highlight>bouwproject</Highlight>.
          </Headline>

          <Spacer y={8} />
          <Spacer y={8} className={phabletOnly} />
          <ServiceList services={data.services.nodes} aboveTheFold={true} />
        </Container>
      </div>

      <Container as="section">
        <Spacer y={3} />
        <Spacer y={12} className={phabletOnly} />
        <HeaderPrimary>
          Jouw eigen project starten?{' '}
          <Highlight as={Link} to="/contacteer-ons">
            Neem contact op &rarr;
          </Highlight>
        </HeaderPrimary>
        <Spacer y={10} />
        <IconList
          items={[
            {
              title: 'Woning op maat',
              description:
                'Ons betrokken team helpt jou, je droomproject te realiseren. ',
              icon: ArchitectureIcon,
            },
            {
              title: 'Ruwbouwwerken',
              description: 'Gespecialiseerd in funderings- en ruwbouwwerken.',
              icon: CraneIcon,
            },
            {
              title: 'Winddichte woning',
              description:
                'Door onze hechte samenwerking met andere firma’s, is er steeds een goede opvolging gedurende je ganse bouwproject.',
              icon: HouseIcon,
            },
            {
              title: 'Kwaliteit voorop',
              description:
                'Samen met onze jarenlange ervaring en het gebruik kwalitatief sterke bouwmaterialen kan je met een gerust hart met ons aan de slag.',
              icon: MedalIcon,
            },
          ]}
        />
      </Container>

      <Spacer y={10} />
      <section>
        <div className={aboutUsBoxTopCss}>
          <svg
            width="451"
            height="47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={bricksCss}
          >
            <path d="M451 47h-63V26h63zM383 47h-63V26h63zM417 21h-63V0h63zM349 21h-63V0h63zM315 47h-63V26h63zM247 47h-63V26h63zM280 21h-62V0h62zM179 47h-63V26h63zM111 47H48V26h63zM144 21H82V0h62zM76 21H14V0h62zM42 47h-62V26h62zM8 21h-62V0H8z" />
          </svg>
          <svg
            width="389"
            height="47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(bricksCss, phabletOnly)}
          >
            <path
              fill="#212121"
              d="M438 21h-63V0h63zM404 47h-63V26h63zM336 47h-63V26h63zM301 21h-62V0h62zM268 47h-63V26h63zM200 47h-63V26h63zM233 21h-62V0h62zM165 21h-62V0h62zM132 47H69V26h63zM64 47H1V26h63zM97 21H35V0h62z"
            />
          </svg>
        </div>
        <div className={aboutUsBoxCss}>
          <Container as="div">
            <div className={aboutUsRowCss}>
              <div className={aboutUsLeftSideCss}>
                <h1 className={aboutUsTitleCss}>Over Van Woensel Woningbouw</h1>
                <Highlight
                  as={Link}
                  to="/over-ons"
                  className={clsx(desktopOnly, aboutUsReadMoreCss)}
                >
                  Lees meer &rarr;
                </Highlight>
              </div>
              <div className={aboutUsRightText}>
                <Text size="large">
                  Onze firma is een echt familiebedrijf dat al gedurende 4
                  generaties overgaat van vader op zoon. Onze grootvader Emiel
                  Van Woensel is in 1948 gestart met een kleine firma uit de
                  grond te stampen. Al snel gingen zijn twee zonen Jul en Walter
                  met hem mee naar de werf om het bouwvakkersberoep te leren en
                  werd de firma steeds bekender in de omgeving van
                  Heist-op-den-Berg en omstreken als ‘de mannen van Van Noten‘ !
                  Na het pensioen van onze grootvader stapten ook wij, Johan en
                  Chris, de twee zonen van Jul, mee in de firma. ‘Van Woensel
                  Woningbouw’ was geboren.
                </Text>
              </div>

              <Highlight
                as={Link}
                to="/over-ons"
                className={clsx(hideDesktop, aboutUsReadMoreCss)}
              >
                Lees meer &rarr;
              </Highlight>
            </div>
          </Container>
        </div>

        <div className={aboutUsBoxTopCss}>
          <svg
            width="667"
            height="48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={bricksCss}
          >
            <path d="M-49 0h63v21h-63zM19 0h63v21H19zM-15 26h63v21h-63zM87 0h63v21H87zM155 0h63v21h-63zM122 26h62v21h-62zM190 26h62v21h-62zM223 0h63v21h-63zM292 0h62v21h-62zM258 26h62v21h-62zM502 48h63V27h-63zM468 22h63V1h-63zM536 22h63V1h-63zM570 48h63V27h-63zM604 22h63V1h-63z" />
          </svg>
          <svg
            width="173"
            height="48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={bricksCss}
          >
            <path d="M34 48h63V27H34zM0 21h63V1H0zM68 21h63V1H68zM102 48h63V27h-63zM170 48h63V27h-63zM136 21h63V1h-63z" />
          </svg>
        </div>
      </section>
    </Layout>
  );
}

export function Head() {
  return <Seo title="Home" />;
}

export const query = graphql`
  query {
    services: allService {
      nodes {
        ...ServiceListFragment
      }
    }
  }
`;
