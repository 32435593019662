import { Text } from "../../components/Text";
import { iconListCss, iconItemCss, iconContainerCss, textContainerCss, iconCss, iconTitleCss, iconDescriptionCss } from "./index.css";

type IconItem = {
  title: string,
  description: string,
  icon: React.ElementType,
}

export const IconList: React.FC<{ items: Array<IconItem> }> = function IconList({ items = [] }) {
  return (
    <ul className={iconListCss}>
      {items.map(({ title, description, icon: Icon }) => {
        return (<li key={title} className={iconItemCss}>
          <div className={iconContainerCss}><Icon className={iconCss} /></div>
          <div className={textContainerCss}>
            <span className={iconTitleCss}>{title}</span>
            <Text className={iconDescriptionCss} variant="ghost">{description}</Text></div>
        </li>)
      })}
    </ul>
  );
}
