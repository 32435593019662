import React from 'react';

const HouseIcon = ({
  width = null,
  height = null,
  color = '#EFBC1D',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.75 47h4.2a.96.96 0 110 1.9H.96a.96.96 0 010-1.9h4.2V25.4H.96a.96.96 0 01-.68-1.62l9.17-9.17a.96.96 0 011.35 1.35L3.26 23.5h3.78L23.78 6.76a.96.96 0 011.35 0L41.86 23.5h3.78L24.45 2.3l-8.3 8.3a.96.96 0 11-1.35-1.34L23.78.28a.96.96 0 011.35 0l23.5 23.5a.96.96 0 01-.68 1.63h-4.2v7.16a.96.96 0 11-1.91 0v-7.16h-.37c-.25 0-.5-.1-.68-.28L24.45 8.79 8.11 25.13a.96.96 0 01-.67.28h-.37v21.58h11.46V28.85c0-.53.43-.96.96-.96h9.93c.53 0 .95.43.95.96v18.14h11.47v-7.54a.96.96 0 011.9 0v7.54zm-23.3-17.2V47h8.01V29.8h-8.02z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.43 27.9h5.35c.53 0 .95.42.95.95v9.55c0 .53-.42.95-.95.95h-5.35a.96.96 0 01-.95-.95v-9.55c0-.53.42-.96.95-.96zm.96 9.54h3.43V29.8H34.4v7.64zM10.12 27.9h5.35c.53 0 .96.42.96.95v9.55c0 .53-.43.95-.96.95h-5.35a.96.96 0 01-.95-.95v-9.55c0-.53.43-.96.95-.96zm.96 9.54h3.44V29.8h-3.44v7.64zM20.06 20.09a4.4 4.4 0 118.8 0 4.4 4.4 0 01-8.8 0zm1.9 0a2.49 2.49 0 104.98 0 2.49 2.49 0 00-4.97 0z"
        fill={color}
      />
      <path
        d="M23.31 37.72a.96.96 0 00-1.35 0 .96.96 0 000 1.35.96.96 0 001.35 0 .96.96 0 000-1.35zM13.38 12.03a.96.96 0 00-1.35 0 .96.96 0 000 1.35.96.96 0 001.35 0 .96.96 0 000-1.35zM43.47 35.08a.96.96 0 00-1.35 0 .96.96 0 000 1.35.96.96 0 001.35 0 .96.96 0 000-1.35z"
        fill={color}
      />
    </svg>
  );
};

export default HouseIcon;
