import React from 'react';

const ArchitectureIcon = ({
  width = null,
  height = null,
  color = '#EFBC1D',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M48.49 30.27a.82.82 0 00-.9.2l-3.49 3.66V12.36l3.33-3.6a.82.82 0 00-.04-1.15l-1.2-1.11 1.66-1.8a.82.82 0 00-.04-1.15L44.2.22a.82.82 0 00-1.15.04l-5.8 6.27H8.97A4.09 4.09 0 004.9 2.45h-.82A4.09 4.09 0 000 6.53v35.12c0 .45.37.81.82.81h12.36l-1.72 2.06a.82.82 0 00.11 1.15l3.77 3.14c.34.29.86.24 1.15-.1l4.18-5.03c.28-.35.47-.77.53-1.22h14.97l-4.91 5.16a.82.82 0 00.59 1.38h16.33c.45 0 .82-.37.82-.82V31.03c0-.33-.2-.63-.51-.76zM45.68 8.25l-1.58 1.7v-1.2l.98-1.06.6.56zm-3.21-.14l1.41-1.52-2.4-2.22-8.32 8.99 2.4 2.22 6.9-7.47zm-8.48 8.24l-1.88.55.4-1.92L34 16.35zM46.1 4.19L43.7 1.97l-1.1 1.2 2.39 2.22 1.1-1.2zm-42-.1h.81c1.35 0 2.45 1.09 2.45 2.44V37.6a4.04 4.04 0 00-2.45-.84h-.82c-.88 0-1.74.3-2.45.84V6.53c0-1.35 1.1-2.45 2.45-2.45zm.81 34.3h-.82a2.45 2.45 0 00-2.45 2.44h5.72c0-1.35-1.1-2.45-2.45-2.45zm14.51 4.25l-.52.63-2.51-2.1.52-.62c.29-.35.8-.4 1.15-.1l1.26 1.04c.34.28.39.8.1 1.14zm-6.17 2.3l2.51 2.1 2.1-2.52-2.52-2.09-2.09 2.51zm7.62-4.1c-.14-.23-.3-.44-.51-.6l-1.25-1.05a2.45 2.45 0 00-3.46.31l-1.1 1.33H8.98V8.17h26.76l-4.33 4.68a.8.8 0 00-.17.3l-.01.03-.02.05-.97 4.66a.82.82 0 001.02.95l4.58-1.33.03-.01.03-.02.06-.02a.8.8 0 00.25-.18l6.26-6.76v25.33l-4.75 4.98H20.86zm12.9 6.52h13.6V33.07l-13.6 14.3z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        d="M39.2 45.73a.82.82 0 01-.58-1.4l5.72-5.7a.82.82 0 011.4.57v5.71c0 .46-.37.82-.82.82H39.2zm4.9-1.63v-2.93l-2.93 2.93h2.93zM35.93 31.03h-5.71v-8.98a.83.83 0 00-.24-.58l-4.9-4.9a.82.82 0 00-1.16 0l-4.9 4.9a.83.83 0 00-.24.58v8.98h-5.71a.82.82 0 100 1.63h22.86a.82.82 0 100-1.63zM24.5 18.3l2.93 2.93h-5.86l2.93-2.93zm4.08 12.73v-8.17h-8.16v8.17h8.16z"
        fill={color}
      />
      <path
        d="M23.68 13.06h1.64v1.64h-1.64v-1.63zM23.68 34.3h1.64v1.63h-1.64V34.3z"
        fill={color}
      />
    </svg>
  );
};

export default ArchitectureIcon;
