import React from 'react';

const CraneIcon = ({
  width = null,
  height = null,
  color = '#EFBC1D',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.894 9.371h-3.87L19.803.081l-.007-.004a1.026 1.026 0 00-.95.09L4.84 9.37H3.107a3.055 3.055 0 100 6.113h1.019v6.112H1.07c-.563 0-1.019.456-1.019 1.019v8.15c0 .562.456 1.018 1.019 1.018h8.15c.562 0 1.018-.456 1.018-1.018v-8.15c0-.563-.456-1.019-1.019-1.019H6.163v-6.112h8.15v30.562h-3.056A3.06 3.06 0 008.2 49.102v2.037c0 .563.456 1.019 1.018 1.019h5.603a1.019 1.019 0 100-2.037h-4.584V49.1a1.02 1.02 0 011.019-1.018h16.3a1.02 1.02 0 011.018 1.019v1.019h-4.584a1.019 1.019 0 100 2.037h5.603c.563 0 1.019-.456 1.019-1.019v-2.037a3.06 3.06 0 00-3.056-3.056H24.5V15.484h14.262v4.146c0 .27.108.53.299.72l1.739 1.74v6.637c0 .563.456 1.019 1.019 1.019a1.02 1.02 0 010 2.037c-.393 0-.755-.23-.922-.585a1.02 1.02 0 00-1.844.869 3.07 3.07 0 002.766 1.754 3.06 3.06 0 003.056-3.056 3.061 3.061 0 00-2.038-2.882V22.09l1.74-1.74c.19-.19.298-.45.298-.72v-4.146h1.019a3.055 3.055 0 100-6.113zM8.2 29.746H2.088v-6.112h6.113v6.112zm8.15 0h4.671l-4.671-4.671v4.671zm0 3.478l4.671 4.672h-4.671v-4.672zm6.112-1.44H17.79l4.672 4.671v-4.672zm-4.672-8.15h4.672v4.671l-4.672-4.671zm-1.44-6.71v4.672h4.671l-4.671-4.672zm6.112 23.01v4.67l-4.672-4.67h4.672zm0-24.45H17.79l4.672 4.671v-4.671zm-.355-2.037h-5.403l.618-2.038h4.168l.617 2.038zM19.407 4.53L17.94 9.371h2.934l-1.467-4.84zm1.602-1.738l15.733 6.58h-13.74l-1.993-6.58zm-5.198 6.58l1.805-5.959L8.55 9.371h7.26zM2.088 12.427c0-.563.457-1.019 1.02-1.019h12.086l-.618 2.038H3.107a1.02 1.02 0 01-1.019-1.02zm18.934 33.618l-4.671-4.672v4.672h4.671zm21.815-26.838l-1.019 1.019-1.018-1.019v-3.724h2.037v3.724zm-18.6-5.761h21.657a1.019 1.019 0 000-2.038H23.62l.617 2.038z"
        fill={color}
      />
      <path
        d="M20.425 51.14a1.019 1.019 0 11-2.037 0 1.019 1.019 0 012.037 0z"
        fill={color}
      />
    </svg>
  );
};

export default CraneIcon;
